<template>
  <div class="left">
    <div class="area">
      <div class="area_title">
        学校概况
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content">
        <div class="overview">
          <div class="overview_left">
            <div class="circle"></div>
            <div class="overview_icon icon1"></div>
          </div>
          <div class="overview_right">
            <div class="text">9888</div>
            <div class="text_tip">学生人数/人</div>
          </div>
        </div>
        <div class="overview">
          <div class="overview_left">
            <div class="circle"></div>
            <div class="overview_icon icon2"></div>
          </div>
          <div class="overview_right">
            <div class="text">898</div>
            <div class="text_tip">教职工人数/人</div>
          </div>
        </div>
        <div class="overview" style="margin-bottom: 0;">
          <div class="overview_left">
            <div class="circle"></div>
            <div class="overview_icon icon3"></div>
          </div>
          <div class="overview_right">
            <div class="text">16</div>
            <div class="text_tip">学院个数/个</div>
          </div>
        </div>
        <div class="overview" style="margin-bottom: 0;">
          <div class="overview_left">
            <div class="circle"></div>
            <div class="overview_icon icon4"></div>
          </div>
          <div class="overview_right">
            <div class="text">48</div>
            <div class="text_tip">专业/个</div>
          </div>
        </div>
      </div>
    </div>
    <div class="area">
      <div class="area_title">
        资源概况
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content">
        <div class="build">
          <div class="build_icon"></div>
          <div class="build_line"></div>
          <div class="overview_right">
            <div class="text">210760</div>
            <div class="text_tip">建筑面积/平米</div>
          </div>
        </div>
        <div class="use">
          <div class="use_item">
            <div class="use_label" style="text-align: end">教室</div>
            <div class="use_bar">
              <div class="bar">
                <div class="bar_"></div>
              </div>
              <div class="bar_tip">
                <div class="bar_tip_ract"></div>
                <div class="bar_tip_text">使用率</div>
                <div class="bar_tip_num">80%</div>
              </div>
            </div>
            <div class="use_label">1200<span>间</span></div>
          </div>
          <div class="use_item">
            <div class="use_label" style="text-align: end">实验室</div>
            <div class="use_bar">
              <div class="bar">
                <div class="bar_" style="width: 100%;"></div>
              </div>
              <div class="bar_tip">
                <div class="bar_tip_ract"></div>
                <div class="bar_tip_text">使用率</div>
                <div class="bar_tip_num">100%</div>
              </div>
            </div>
            <div class="use_label">100<span>间</span></div>
          </div>
          <div class="use_item">
            <div class="use_label" style="text-align: end">办公室</div>
            <div class="use_bar">
              <div class="bar">
                <div class="bar_" style="width: 90%;"></div>
              </div>
              <div class="bar_tip">
                <div class="bar_tip_ract"></div>
                <div class="bar_tip_text">使用率</div>
                <div class="bar_tip_num">90%</div>
              </div>
            </div>
            <div class="use_label">50<span>间</span></div>
          </div>
          <div class="use_item">
            <div class="use_label" style="text-align: end">宿舍</div>
            <div class="use_bar">
              <div class="bar">
                <div class="bar_"></div>
              </div>
              <div class="bar_tip">
                <div class="bar_tip_ract"></div>
                <div class="bar_tip_text">使用率</div>
                <div class="bar_tip_num">80%</div>
              </div>
            </div>
            <div class="use_label">600<span>间</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="area" style="height: calc(100% - 610px);">
      <div class="area_title">
        重点监控
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content_" style="padding-bottom: 0;">
        <div class="video_container">
          <div class="video" v-for="i of 6" @click="startVideo">
            <div class="video_name">503</div>
            <div class="video_img"></div>
          </div>
        </div>
      </div>
    </div>
    <chose-building/>
  </div>
  <div class="right">
    <div class="area">
      <div class="area_title">
        告警统计
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="echarts_content">
        <div id="echarts" style="width: 100%;height: 250px;"></div>
        <div class="echartcircle"></div>
        <div class="label_container">
          <div class="lebal">
            <div class="label_icon_"></div>
            <div class="label_text">告警总数</div>
            <div class="label_text" style="color: #F0406A">17个</div>
            <div class="label_line"></div>
          </div>
          <div class="lebal" v-for="(item,index) of warntotal" :key="index">
            <div class="label_icon" :style="{backgroundColor:color[index]}"></div>
            <div class="label_text" style="cursor: pointer">{{ item.name }}</div>
            <div class="label_text">{{ item.value }}个</div>
            <div class="label_line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="area" style="height: calc(100% - 355px);" @mouseover="scrollFlag = false"
         @mouseleave="scrollFlag = true">
      <div class="area_title">
        告警列表
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="warn_title">
        <div class="warn_title_text" style="width: 32px;">序号</div>
        <div class="warn_title_text" style="width: 50px;">类型</div>
        <div class="warn_title_text" style="width: 120px;">描述</div>
        <div class="warn_title_text" style="width: 120px;">上报时间</div>
      </div>
      <div class="warn_container" id="warn_container">
        <div class="warn_container_">
          <div class="warn_item" v-for="(item,index) of warnList" :key="index" :class="index%2===0?'bg':''"
               @click="choseWarn(item)">
            <div class="warn_text" style="width: 32px;">{{ index + 1 }}</div>
            <div class="warn_text" style="width: 70px;" :style="{color:color[item.color]}">
              <div class="ract_left" :style="{backgroundColor:color[item.color]}"></div>
              <div class="ract_right" :style="{backgroundColor:color[item.color]}"></div>
              {{ item.subtype }}
            </div>
            <div class="warn_text" style="width: 130px;">{{ item.remark }}</div>
            <div class="warn_text" style="width: 130px;color: #BDBDBD;">{{ item.time }}</div>
          </div>
        </div>
        <div id="left_inner1" v-show="warnList.length > 10" class="warn_container_">
          <div class="warn_item" v-for="(item,index) of warnList" :key="index" :class="index%2===0?'bg':''"
               @click="choseWarn(item)">
            <div class="warn_text" style="width: 32px;">{{ index + 1 }}</div>
            <div class="warn_text" style="width: 70px;" :style="{color:color[item.color]}">
              <div class="ract_left" :style="{backgroundColor:color[item.color]}"></div>
              <div class="ract_right" :style="{backgroundColor:color[item.color]}"></div>
              {{ item.subtype }}
            </div>
            <div class="warn_text" style="width: 130px;">{{ item.remark }}</div>
            <div class="warn_text" style="width: 130px;color: #BDBDBD;">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="video_dialog" id="video_dialog" v-show="videoShow">
    <div class="video_top">
      <div class="video_name_">{{ videoName }}</div>
      <div class="video_close_" @click="close"></div>
    </div>
    <div class="video_con">
      <video id="videoElement" controls></video>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Global from "../js/global";
import flvjs from "flv.js";
import Message from "../js/Message";
import ChoseBuilding from "./ChoseBuilding";

let flvPlayer;
export default {
  name: "Msite",
  components: {ChoseBuilding},
  data() {
    return {
      videoShow: false,
      scrollFlag: true,
      videoName: "",
      time: null,
      warntotal: [
        {
          name: "教学一体机",
          value: 3
        },
        {
          name: "广播",
          value: 3
        },
        {
          name: "入侵报警",
          value: 9
        },
        {
          name: "消防",
          value: 2
        }
      ],
      warnList: [],
      warnList_: [
        {
          type: "教学一体机",
          color: 0,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "广播",
          color: 1,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "入侵报警",
          color: 2,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "消防",
          color: 3,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "教学一体机",
          color: 0,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "广播",
          color: 1,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "入侵报警",
          color: 2,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "消防",
          color: 3,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "入侵报警",
          color: 2,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "消防",
          color: 3,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "教学一体机",
          color: 0,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        },
        {
          type: "广播",
          color: 1,
          remark: "描述文字描述文字描述",
          time: "2022-10-08 12:00"
        }
      ],
      color: ["#D27D69", "#F8D8B0", "#30D6C2", "#BF79AF"]
    };
  },
  mounted() {
    this.initEcharts();
    this.left_scroll();
    this.getWarnPoints();
    if (Global.alarmPlane){
      Global.alarmPlane.forEach( item => item.setEnabled(true) )
    }
    // Global.Func.showBuildingInfo()
    // this.left_scroll();
  },
  unmounted() {
    if (Global.alarmPlane){
      Global.alarmPlane.forEach( item => item.setEnabled(false) )
    }
    this.deleteVideo();
    clearInterval(this.time);
    if (window.myChart_1) {
      window.myChart_1.dispose();
      window.myChart_1 = null;
    }
  },
  methods: {
    getWarnPoints() {
      let arr = Global.findEquipment('warn')
      this.warnList = arr
    },
    close() {
      this.videoShow = false;
      this.deleteVideo();
      Global.recoverEle("video_dialog")
    },
    deleteVideo() {
      Message.deleteVideo()
    },
    startVideo() {
      let data = {
        name: "建筑工地",
        img: "http://krqi.dsmynas.com:9006/data/image/2.jpg",
        url: "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv"
      };
      this.videoName = data.name;
      this.videoShow = true;
      this.deleteVideo();
      Message.startVideo(data, "videoElement", "video_dialog")
    },
    choseWarn(data) {
      Global.choseEquipment(data)
    },
    left_scroll() {
      const that = this;
      let parent = document.getElementById("warn_container");
      let child = document.getElementById("left_inner1");
      this.time = setInterval(function () {
        if (parent.scrollTop >= child.scrollHeight) {
          parent.scrollTop = 0;
        } else {
          if (that.scrollFlag) {
            parent.scrollTop++;
          }
        }
      }, 50);
    },
    initEcharts() {
      let container = document.getElementById("echarts");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option_ = {
        color: this.color,
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "55%"],
            center: ["25%", "50%"],
            itemStyle: {
              borderRadius: 10
            },
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: this.warntotal
          }
        ]
      };
      myChart_.setOption(option_);
      window.myChart_1 = myChart_;
    },
    loadAnimation() {
      Global.Func.animation();
    },
  }
};
</script>

<style scoped>
#videoElement {
  width: 100%;
  height: 100%;
}

.warn_container_ {
  width: 100%;
}

.warn_item:last-child {
  margin-bottom: 0 !important;
}

.warn_item .warn_text {
  transform: scale(0.8);
}

.bg {
  background-image: linear-gradient(to right, rgba(88, 173, 169, 0.7) 0%, rgba(19, 21, 21, 0.1) 100%);
}

.label_icon_ {
  width: 27px;
  height: 27px;
  background-image: url(../assets/image/icon/warn_icon.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.label_line {
  width: 100%;
  bottom: 0;
  height: 1px;
  position: absolute;
  left: 0;
  background-image: linear-gradient(to right, rgba(144, 249, 252, 1) 0%, rgba(125, 125, 125, 0.4) 100%);
}

.label_text {
  margin-top: 4px;
  font-size: 16px;
  color: #ffffff;
}

.label_icon {
  width: 14px;
  height: 14px;
  margin-left: 6.5px;
  background-color: #fff;
}

.lebal {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 47px;
}

.label_container {
  position: absolute;
  width: 177px;
  right: 10px;
  top: 10px;
}

.echartcircle {
  width: 80px;
  height: 80px;
  background-image: url(../assets/image/icon/Ellipse13.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  left: 50px;
  top: 50%;
  margin-top: -40px;
  animation: whirling 1.5s linear infinite;
}

@keyframes whirling {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

.echarts_content {
  width: 100%;
  position: relative;
}

.ract_left {
  width: 4px;
  height: 4px;
  position: absolute;
  left: -5px;
  top: 50%;
  margin-top: -2px;
  background-color: #fff;
}

.ract_right {
  width: 4px;
  height: 4px;
  position: absolute;
  right: -5px;
  top: 50%;
  margin-top: -2px;
  background-color: #fff;
}

.warn_container {
  width: 100%;
  height: calc(100% - 110px);
  margin-top: 10px;
  overflow: scroll;
}

.warn_item {
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  height: 27px;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.warn_item .warn_text {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  position: relative;
}

.warn_title_text {
  font-size: 16px;
  line-height: 37.5px;
  color: #153935;
  text-align: center;
}

.warn_title {
  width: 100%;
  height: 37.5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #4CB0A4;
  /*background-image: radial-gradient(rgba(88, 173, 169, 0.6), rgba(19, 21, 21, 0.1));*/
}

.video_name {
  color: #fff;
}

.video {
  margin-bottom: 15px;
  cursor: pointer;
}

.video_img {
  width: 170px;
  height: 96px;
  background-image: url(../assets/image/bg/video.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.video_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  overflow: scroll;
}

.area_content_ {
  padding: 20px 10px;
  height: calc(100% - 40px);
}

.bar_tip_num {
  font-size: 12px;

}

.bar_tip_text {
  font-size: 12px;
  margin: 0 5px;
}

.bar_tip_ract {
  width: 10px;
  height: 10px;
  background-color: #30D6C2;
  margin-top: -3px;
}

.bar_tip {
  display: flex;
  align-items: center;
  margin-top: 5px;
  transform: scale(0.8);
  margin-left: -16px;
}

.bar_ {
  width: 80%;
  height: 10px;
  background-color: #30D6C2;
}

.bar {
  width: 100%;
  height: 10px;
  background-color: #828282;
}

.use {
  width: 100%;
  margin-top: 32px;
}

.use_label span {
  font-size: 10px;
}

.use_item {
  width: 100%;
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  margin-bottom: 15px;
}

.use_item:last-child {
  margin-bottom: 0;
}

.use_bar {
  width: calc(100% - 150px);
}

.use_label {
  width: 58px;
  font-size: 16px;
}

.build_line {
  width: 2px;
  height: 44px;
  background-color: #56CCF2;
  margin-left: 20px;
  margin-right: 12px;
}

.build_icon {
  width: 53px;
  height: 53px;
  background-image: url(../assets/image/icon/build.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.build {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon1 {
  background-image: url(../assets/image/icon/xuesheng.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon2 {
  background-image: url(../assets/image/icon/laoshi.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon3 {
  background-image: url(../assets/image/icon/xuexiao.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon4 {
  background-image: url(../assets/image/icon/kemu.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.overview_left {
  margin-right: 14px;
}

.text {
  font-size: 24px;
  color: #30D6C2;
  margin-bottom: 5px;
}

.text_tip {
  font-size: 16px;
  color: #F2F2F2;
}

.overview_icon {
  width: 20px;
  height: 20px;
}

.circle {
  width: 19px;
  height: 19px;
  margin-bottom: 10px;
  background-color: rgba(48, 204, 214, 0.3);
  border-radius: 50%;
}

.area_content {
  padding: 20px 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0px;
}


.overview {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 138px;
}
</style>
